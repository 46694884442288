import React from 'react'
import HeroSection from '../../sections/HeroSection/HeroSection'
import FeatureSection from '../../sections/FeatureSection/FeatureSection'
import IndustrySection from '../../sections/IndustrySection/IndustrySection'
import WhyMerchMind from '../../sections/WhyMerchMind/WhyMerchMind'
import KeyCapabilities from '../../sections/KeyCapabilities/KeyCapabilities'
import GetStartedSection from '../../sections/GetStartedSection/GetStartedSection'
import InformationSection from '../../sections/InformationSection/InformationSection'

const Home = () => {
    return (
        <div className='flex flex-col item-center justify-center'>
            <HeroSection />

            <InformationSection />

            {/* <FeatureSection /> */}

            {/* <IndustrySection /> */}

            {/* <WhyMerchMind /> */}

            {/* <KeyCapabilities /> */}

            <GetStartedSection />
        </div>
    )
}

export default Home