import React from 'react'
import { Link } from 'react-router-dom'

const RequestADemo = () => {
    return (
        <div className='w-full h-[800px] flex max-sm:flex max-sm:flex-col items-center justify-center gap-5 px-20 max-sm:px-4'>
            {/* left */}
            <div className='w-1/2 max-sm:w-full'>
                <img src="https://cdn.pixabay.com/photo/2018/01/11/21/27/desk-3076954_1280.jpg" alt="" />
            </div>

            {/* right */}
            <div className='w-1/2 max-sm:w-full flex flex-col items-start justify-center gap-5'>
                <h1 className=' text-2xl max-sm:text-lg'>
                    <span className='font-bold'>Email: </span>
                    <span className='text-xl max-sm:sm'>aa@merchmind.net</span>
                </h1>
                <h2 className=' text-2xl max-sm:text-lg'>
                    <span className='font-bold'>Mobile: </span>
                    <span className='text-xl max-sm:sm'>0526428339</span>
                </h2>
                {/* <Link to='https://www.linkedin.com/in/ayaz-ali-iim/' target='blank'>
                    <h3 className=' text-2xl max-sm:text-lg'>
                        <span className='font-bold'>LinkedIn: </span>
                        <span className='text-xl max-sm:sm text-gray-500'>https://www.linkedin.com/in/ayaz-ali-iim/</span>
                    </h3>
                </Link> */}
            </div>
        </div>
    )
}

export default RequestADemo;