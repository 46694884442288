export const links = [
    {
        id: 1,
        name: "Products",
        mainLink: '/products',
        submenu: true,
        sublinks: [
            {
                id: 11,
                Head: "Financial Planning & Reporting",
                toLink: '/products/financialplanning'
            },
            {
                id: 12,
                Head: "Merchandise Planning",
                toLink: '/products/merchandiseplanning'
            },
            {
                id: 13,
                Head: "Assortment Planning",
                toLink: '/products/assortmentplanning'
            },
            {
                id: 14,
                Head: "Pricing Module",
                toLink: '/products/pricingmodule'
            },
            {
                id: 15,
                Head: "Business Intelligence",
                toLink: '/products/businessintelligence'
            }
        ]
    },
    {
        id: 2,
        name: "Industries",
        mainLink: '/industries',
        submenu: true,
        sublinks: [
            {
                id: 21,
                Head: "Pharma",
            },
            {
                id: 22,
                Head: "Retail",
            },
            {
                id: 23,
                Head: "High Tech",
            },
            {
                id: 24,
                Head: "Automotive",
            },
            {
                id: 25,
                Head: "E-Commerce",
            },
            {
                id: 27,
                Head: "FMCG",
            }
        ]
    },
    {
        id: 3,
        name: "Company",
        mainLink: '/company',
        submenu: true,
        sublinks: [
            {
                id: 31,
                Head: "About",
                toLink: '/about'
            }
        ]
    }
];