import React from 'react'
// import { GiPieChart } from "react-icons/gi";
// import { AiOutlineStock } from "react-icons/ai";
// import { SiBnbchain } from "react-icons/si";
// import { FaPeopleGroup } from "react-icons/fa6";
// import { BiAnalyse } from "react-icons/bi";

import { FaChartLine } from "react-icons/fa6";
import { FaChartGantt } from "react-icons/fa6";
import { IoSpeedometerOutline } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";


const FeatureSection = () => {
    return (
        <div className='w-full h-full bg-white flex max-sm:flex max-sm:flex-wrap md:flex-wrap items-center justify-center gap-[3rem] p-10 max-sm:p-3 max-lg:gap-[3rem]'>
            <div className="flex flex-col items-center justify-center gap-6">
                <FaChartLine className='text-6xl max-lg:text-4xl text-green-700' />
                <div className='flex flex-col items-center justify-center'>
                    <p className='text-md max-lg:text-xs max-sm:text-sm text-gray-700'>Increase in sales</p>
                    <h2 className='text-xl max-lg:text-lg font-bold'>Up to 5%</h2>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-6">
                <FaChartGantt className='text-6xl max-lg:text-4xl text-blue-700' />
                <div className='flex flex-col items-center justify-center'>
                    <p className='text-md max-lg:text-xs max-sm:text-sm text-gray-700'>Reduction in inventory</p>
                    <h2 className='text-xl max-lg:text-lg font-bold'>Up to 30%</h2>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-6">
                <IoSpeedometerOutline className='text-6xl max-lg:text-4xl text-pink-700' />
                <div className='flex flex-col items-center justify-center'>
                    <p className='text-md max-lg:text-xs max-sm:text-sm text-gray-700'>Efficency improvement</p>
                    <h2 className='text-xl max-lg:text-lg font-bold'>Up to 50%</h2>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-6">
                <RiDeleteBinLine className='text-6xl max-lg:text-4xl text-purple-700' />
                <div className='flex flex-col items-center justify-center'>
                    <p className='text-md max-lg:text-xs max-sm:text-sm text-gray-700'>Less waste</p>
                    <h2 className='text-xl max-lg:text-lg font-bold'>Up to 50%</h2>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-6">
                <MdOutlineShoppingCartCheckout className='text-6xl max-lg:text-4xl text-blue-400' />
                <div className='flex flex-col items-center justify-center'>
                    <p className='text-md max-lg:text-xs max-sm:text-sm text-gray-700'>Fewer stock-outs</p>
                    <h2 className='text-xl max-lg:text-lg font-bold'>Up to 30%</h2>
                </div>
            </div>
        </div>
    )
}

export default FeatureSection