import React from 'react'

const Industries = () => {
    return (
        <div className='w-full h-[800px] flex items-center justify-center'>
            <h1 className='text-2xl'>Industries...Coming soon...</h1>
        </div>
    )
}

export default Industries