import React from 'react'
import { BsFillArrowRightSquareFill } from 'react-icons/bs'
import { MerchandisePlanningData } from '../../../data'

const MerchandisePlanning = () => {
    return (
        <div className='w-full flex max-sm:flex max-sm:flex-col items-center justify-center gap-8 max-sm:gap-10 p-[10rem] max-sm:px-4 max-lg:px-6'>
            {/* left */}
            <div className='w-1/2 h-[500px] max-sm:w-full'>
                <img src="https://cdn.pixabay.com/photo/2024/04/27/07/24/ai-generated-8723287_1280.jpg" alt="" className='transform filter drop-shadow-2xl rounded-lg w-full h-full object-cover' />
            </div>

            {/* right */}
            <div className='w-1/2 h-full max-sm:w-full flex flex-col justify-center gap-3'>
                <h1 className='text-3xl max-lg:text-3xl font-bold'>Merchandise Planning</h1>
                <div className='w-[200px] h-[3px] bg-blue-700'></div>
                {
                    MerchandisePlanningData.map((item) => (
                        <div key={item.id} className='w-full flex items-start gap-2'>
                            <span className='mt-1'><BsFillArrowRightSquareFill /></span>
                            <span>{item.points}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default MerchandisePlanning