import React from 'react'
import Button from '../../customcomponents/Button/Button'
import { Link } from 'react-router-dom'

// https://cdn.pixabay.com/photo/2021/03/19/13/35/computer-6107592_1280.png
// https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/eegwirnsjcpc7uuzukaq.png
// https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/rtzaxgqnrdxbbixwattp.png
// https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/dd7se7vom2xyry9xbn2z.png
// https://res.cloudinary.com/prabhuwest/image/upload/v1717146622/merchmind/drgroikrttzhxhsqhwgj.png
// https://res.cloudinary.com/prabhuwest/image/upload/v1717145912/merchmind/rf5mvqqwtofyozxpkioj.jpg
// https://res.cloudinary.com/prabhuwest/image/upload/v1717146007/merchmind/gwa6zffddrgua3b1x3hs.jpg
// https://res.cloudinary.com/prabhuwest/image/upload/v1717148732/ai_mind_square_sgjydo.png

const HeroSection = () => {
    return (
        <div className="z-0 bg-hero-bg w-full h-[800px] max-sm:h-[950px] bg-cover bg-fixed lg:flex max-sm:flex max-sm:flex-col-reverse items-center justify-center p-7 max-sm:p-3">
            {/* left section */}
            <div className='lg:w-1/2 max-lg:w-2/3 max-sm:w-full lg:mt-0 max-sm:mt-[2rem] max-lg:mt-[13rem] flex flex-col lg:items-start lg:justify-start max-sm:items-center max-sm:justify-center max-lg:items-start gap-7 p-7 text-white'>
                <h1 className='text-7xl max-lg:text-5xl max-sm:text-3xl font-extrabold text-white text-stroke lg:text-start max-sm:text-center lg:leading-tight max-sm:leading-tight max-lg:leading-tight'>Confidence In An Uncertain World</h1>
                <p className='text-xl max-sm:text-md max-lg:text-sm font-[400] text-gray-400 lg:text-start max-sm:text-center lg:leading-relaxed max-sm:leading-8'>Transform how you see, plan, and lead your business with our Connected Planning platform.</p>
                <Link to="/products">
                    <Button
                        btnText="Explore"
                        btnTextColor="text-white"
                        btnBgColor="bg-slate-600"
                        btnBgColorHover="hover:bg-slate-500"
                        btnPadding="px-9 py-4"
                    />
                </Link>
            </div>

            {/* right section */}
            <div className='lg:w-1/2'>
                <img src="https://res.cloudinary.com/prabhuwest/image/upload/v1717146007/merchmind/gwa6zffddrgua3b1x3hs.jpg" alt="" className='lg:hidden max-lg:hidden max-sm:block transform w-full h-full object-cover' />
            </div>
        </div>
    )
}

export default HeroSection