export const industryCardData = [
    {
        id: 1,
        title: 'CONSUMER GOODS',
        desc: 'Control production and profitability',
        img: 'https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/dd7se7vom2xyry9xbn2z.png'
    },
    {
        id: 2,
        title: 'FINANCIAL & BUSINESS SERVICE',
        desc: 'Chart the path to higher returns',
        img: 'https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/dd7se7vom2xyry9xbn2z.png'
    },
    {
        id: 3,
        title: 'MANUFACTURING',
        desc: 'Spark dynamic decision-making',
        img: 'https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/dd7se7vom2xyry9xbn2z.png'
    },
    {
        id: 4,
        title: 'RETAIL',
        desc: 'Create outstanding customer experiences',
        img: 'https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/dd7se7vom2xyry9xbn2z.png'
    },
    {
        id: 5,
        title: 'TECHNOLOGY, MEDIA & TELECOM',
        desc: 'Accelerate growth and profitability',
        img: 'https://res.cloudinary.com/prabhuwest/image/upload/v1717144797/merchmind/dd7se7vom2xyry9xbn2z.png'
    }
]

export const productsLists = [
    {
        id: 1,
        productName: 'Financial Planning & Reporting'
    },
    {
        id: 2,
        productName: 'Merchandise Planning'
    },
    {
        id: 3,
        productName: 'Assortment Planning'
    },
    {
        id: 4,
        productName: 'Business Intelligence'
    },
    {
        id: 5,
        productName: 'Auto Replenishment'
    },
    {
        id: 6,
        productName: 'Pricing Module'
    }
]

export const IndustryLists = [
    {
        id: 1,
        industryName: 'MRO'
    },
    {
        id: 2,
        industryName: 'Retail'
    },
    {
        id: 3,
        industryName: 'High Tech'
    },
    {
        id: 4,
        industryName: 'Automotive'
    },
    {
        id: 5,
        industryName: 'E-Commerce'
    },
    {
        id: 6,
        industryName: 'Manufacturing'
    },
    {
        id: 7,
        industryName: 'Consumer Product'
    },
    {
        id: 8,
        industryName: 'Energy & Resources'
    },
]

export const leaders = [
    {
        id: 1,
        name: 'Satyabrat Nayak',
        position: 'CEO',
        photo: 'https://cdn.pixabay.com/photo/2023/04/28/23/32/ai-generated-7957457_1280.png'
    },
    {
        id: 2,
        name: 'Ayaz Ali',
        position: 'CEO',
        photo: 'https://cdn.pixabay.com/photo/2023/04/28/23/32/ai-generated-7957457_1280.png'
    },
    {
        id: 3,
        name: 'Lorem Ipsum',
        position: 'CEO',
        photo: 'https://cdn.pixabay.com/photo/2023/04/28/23/32/ai-generated-7957457_1280.png'
    }
]

export const FinancialPlanningAndReportingData = [
    {
        id: 1,
        points: 'Aligning financial and merchandise budgets.'
    },
    {
        id: 2,
        points: 'Facilitating a multi-level sales hierarchy: country location-store type-store.'
    },
    {
        id: 3,
        points: 'Supporting a multi-channel strategy encompassing online platforms, physical stores, shop-in-shop setups, corners, etc.'
    },
    {
        id: 4,
        points: 'Streamlining the process of initiating a new financial budget based on KPIs and targets.'
    },
    {
        id: 5,
        points: 'Providing comprehensive historical sales and budget data.'
    },
    {
        id: 6,
        points: 'Creating a financial plan at both total and product hierarchy levels, segmented by season.'
    },
    {
        id: 7,
        points: 'Access to online data regarding sales, markdowns, units sold, retail prices, gross profit, and cost prices.'
    }
]

export const MerchandisePlanningData = [
    {
        id: 1,
        points: 'Automated Opentobuy management.'
    },
    {
        id: 2,
        points: 'Brand projection tool / WSSI.'
    },
    {
        id: 3,
        points: 'Autoreplenishment tool between whs/store & supplier/whs.'
    },
    {
        id: 4,
        points: 'Inter store consolidation.'
    },
    {
        id: 5,
        points: 'SKU efficiency tool.'
    },
    {
        id: 6,
        points: 'Seasonal planning facilitated from top-down and bottom-up & middle out perspectives.'
    },
    {
        id: 7,
        points: 'Daily / Weekly monitoring of various metrics including sales, markdowns, margins, forward cover, closing stock, and OTB (WSSI).'
    },
    {
        id: 8,
        points: 'Ability to reforecast during the season as needed.'
    },
    {
        id: 9,
        points: 'Planning for forward cover and stock rotation.'
    },
    {
        id: 10,
        points: 'Simplified initiation of sales and markdown budgets for existing stock based on historical sell-through, markdowns, and required closing stock percentages.'
    },
    {
        id: 11,
        points: 'Intelligent management of "never out of stock" items.'
    },
    {
        id: 12,
        points: 'Ensuring alignment between financial and merchandise budgets.'
    },
    {
        id: 13,
        points: 'Range control enabled through targets for the number of styles and options.'
    }
]

export const AssortmentPlanningData = [
    {
        id: 1,
        points: 'Optimal Assortment Strategy- Delivers a comprehensive plan at the store and attribute group level, defining an assortment with optimized size ratios, depth, and width.'
    },
    {
        id: 2,
        points: 'Optimal Size Sets- Suggests a demand-driven true size set ratio by rectifying previous instances of overbuying or underbuying, aiming to minimize instances of broken sizes and cut scenarios in stores.'
    },
    {
        id: 3,
        points: 'Ideal Depth- Advises on the appropriate inventory mix amongvarious attribute groups within each store.'
    },
    {
        id: 4,
        points: 'Ideal Width- Recommends the optimal number of styles to showcase for each store and attribute group combination, intelligently adjusting the focus on attribute groups with longer tails while increasing style variety in potential areas.'
    },
]

export const PricingModuleData = [
    {
        id: 1,
        points: 'Determining the best timing, frequency, and depth of markdowns to optimize profit.'
    },
    {
        id: 2,
        points: 'Proposing markdown strategies based on real versus ideal sell-through rates and product life cycles.'
    },
    {
        id: 3,
        points: 'Assessing the impact of markdown proposals on gross profit.'
    },
    {
        id: 4,
        points: 'Flexibility to round prices or utilize predetermined price points.'
    },
    {
        id: 5,
        points: 'Offering age-based and cost-based markdown proposals for aging inventory.'
    },
    {
        id: 6,
        points: 'Establishing ideal sell-through curves using reference products.'
    },
    {
        id: 7,
        points: 'Simplifying the creation of required sell-through curves through graphical interfaces.'
    },
    {
        id: 8,
        points: 'Providing detailed reporting on actual sell-through for each style/color, including bestseller analysis.'
    },
    {
        id: 9,
        points: 'Consolidating all proposal and markdown-related information into a single spreadsheet.'
    },
]

export const BusinessIntelligenceData = [
    {
        id: 1,
        points: 'Empower every role from store manager to CEO with essential business intelligence dashboards.'
    },
    {
        id: 2,
        points: 'Quickly grasp business performance insights and drive better decisions all across brands and retailers.'
    },
    {
        id: 3,
        points: 'Improvement in operational efficiency.'
    },
    {
        id: 4,
        points: 'Automation in decision making.'
    },
    {
        id: 5,
        points: 'Human errors.'
    },
    {
        id: 6,
        points: 'Accuracy in data representation.'
    }
]

export const LeadershipData = [
    {
        id: 1,
        name: 'Ayaz Ali',
        
    }
]


export const demoLinks = [
    {
        name: "Men",
        submenu: true,
        sublinks: [
            {
                Head: "Topwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "Bottomwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "innerwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },

            {
                Head: "sleepwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "footwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
        ],
    },
    {
        name: "Women",
        submenu: true,
        sublinks: [
            {
                Head: "Topwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "Bottomwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "innerwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },

            {
                Head: "sleepwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "footwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
        ],
    },
    {
        name: "Kid's",
        submenu: true,
        sublinks: [
            {
                Head: "Topwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "Bottomwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "innerwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },

            {
                Head: "sleepwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
            {
                Head: "footwear",
                sublink: [
                    { name: "T-shirt", link: "/" },
                    { name: "Casual shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                    { name: "formal shirts", link: "/" },
                ],
            },
        ],
    },
];