import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Footer from './components/Layouts/Footer/Footer';
// import Navbar from './components/Layouts/Navbar/Navbar';
import Products from './pages/Products/Products';
import FinancePlanning from './pages/Products/FincnacePlanning/FincnacePlanning'
import MerchandisePlanning from './pages/Products/MerchandisePlanning/MerchandisePlanning';
import AssortmentPlanning from './pages/Products/AssortmentPlanning/AssortmentPlanning';
import BusinessIntelligence from './pages/Products/BusinessIntelligence/BusinessIntelligence';
import AutoReplenishment from './pages/Products/AutoReplenishment/AutoReplenishment';
import PricingModule from './pages/Products/PricingModule/PricingModule';
import Customers from './pages/Customers/Customers';
import Company from './pages/Company/Company';
import Support from './pages/Support/Support';
import RequestADemo from './pages/RequestADemo/RequestADemo';
import Industries from './pages/Industries/Industries';
import NavbarNew from './components/Layouts/Navbar/NavbarNew';
import About from './pages/About/About';

function App() {
  return (
    <BrowserRouter>
      <div className='app font-poppins'>
        <NavbarNew />
        <Routes>
          <Route path='/*' element={<Home />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/financialplanning' element={<FinancePlanning />} />
          <Route path='/products/merchandiseplanning' element={<MerchandisePlanning />} />
          <Route path='/products/assortmentplanning' element={<AssortmentPlanning />} />
          <Route path='/products/businessintelligence' element={<BusinessIntelligence />} />
          <Route path='/products/autoreplenishment' element={<AutoReplenishment />} />
          <Route path='/products/pricingmodule' element={<PricingModule />} />
          <Route path='/customers' element={<Customers />} />
          <Route path='/company' element={<Company />} />
          <Route path='/industries' element={<Industries />} />
          <Route path='/support' element={<Support />} />
          <Route path='/requestademo' element={<RequestADemo />} />
          <Route path='/about' element={<About />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;