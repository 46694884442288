import React from 'react'
import { FaSquarePollVertical } from "react-icons/fa6";
import { GiPlayerTime } from "react-icons/gi";
import { BiSolidOffer } from "react-icons/bi";
import { IoBusiness } from "react-icons/io5";
import { TbAutomaticGearbox } from "react-icons/tb";
import { MdPriceChange } from "react-icons/md";
import { Link } from 'react-router-dom';


const Products = () => {
    return (
        <div className='w-full h-full'>
            <div className='w-full h-[500px] flex flex-col items-center justify-center gap-6 pt-20 max-sm:px-3'>
                {/* <img src={industry} alt="" className='w-full h-full object-cover' /> */}
                <h1 className='w-full text-center text-5xl max-sm:text-3xl leading-tight font-bold'>Our products, <br /> powered by the industry specific knowledge models.</h1>
                <div className='w-[500px] max-sm:w-[150px] h-1 bg-black'></div>
            </div>

            <div className='px-20 py-10 w-full flex flex-wrap items-center justify-center gap-10'>
                <Link to='/products/financialplanning'>
                    <div className='w-[250px] h-[200px] flex flex-col items-center justify-center gap-4 border p-2 rounded-lg border-gray-300 shadow-2xl shadow-slate-500 hover:drop-shadow-2xl hover:shadow-white/[0.2] transition duration-300 cursor-pointer'>
                        <span className='w-full h-2/3 flex items-center justify-center'>
                            <FaSquarePollVertical className='text-8xl' />
                        </span>
                        <span className='w-full h-1/3'>
                            <h2 className='text-center text-lg font-bold'>Financial Planning & Reporting</h2>
                        </span>
                    </div>
                </Link>
                <Link to='/products/merchandiseplanning'>
                    <div className='w-[250px] h-[200px] flex flex-col items-center justify-center gap-4 border p-2 rounded-lg border-gray-300 shadow-2xl shadow-slate-500 hover:drop-shadow-2xl hover:shadow-white/[0.2] transition duration-300 cursor-pointer'>
                        <span className='w-full h-2/3 flex items-center justify-center'>
                            <GiPlayerTime className='text-8xl' />
                        </span>
                        <span className='w-full h-1/3'>
                            <h2 className='text-center text-lg font-bold'>Merchandise Planning</h2>
                        </span>
                    </div>
                </Link>
                <Link to='/products/assortmentplanning'>
                    <div className='w-[250px] h-[200px] flex flex-col items-center justify-center gap-4 border p-2 rounded-lg border-gray-300 shadow-2xl shadow-slate-500 hover:drop-shadow-2xl hover:shadow-white/[0.2] transition duration-300 cursor-pointer'>
                        <span className='w-full h-2/3 flex items-center justify-center'>
                            <BiSolidOffer className='text-8xl' />
                        </span>
                        <span className='w-full h-1/3'>
                            <h2 className='text-center text-lg font-bold'>Assortment Planning</h2>
                        </span>
                    </div>
                </Link>
                <Link to='/products/businessintelligence'>
                    <div className='w-[250px] h-[200px] flex flex-col items-center justify-center gap-4 border p-2 rounded-lg border-gray-300 shadow-2xl shadow-slate-500 hover:drop-shadow-2xl hover:shadow-white/[0.2] transition duration-300 cursor-pointer'>
                        <span className='w-full h-2/3 flex items-center justify-center'>
                            <IoBusiness className='text-8xl' />
                        </span>
                        <span className='w-full h-1/3'>
                            <h2 className='text-center text-lg font-bold'>Business Intelligence</h2>
                        </span>
                    </div>
                </Link>
                <Link to='/products/autoreplenishment'>
                    <div className='w-[250px] h-[200px] flex flex-col items-center justify-center gap-4 border p-2 rounded-lg border-gray-300 shadow-2xl shadow-slate-500 hover:drop-shadow-2xl hover:shadow-white/[0.2] transition duration-300 cursor-pointer'>
                        <span className='w-full h-2/3 flex items-center justify-center'>
                            <TbAutomaticGearbox className='text-8xl' />
                        </span>
                        <span className='w-full h-1/3'>
                            <h2 className='text-center text-lg font-bold'>Auto Replenishment</h2>
                        </span>
                    </div>
                </Link>
                <Link to='/products/pricingmodule'>
                    <div className='w-[250px] h-[200px] flex flex-col items-center justify-center gap-4 border p-2 rounded-lg border-gray-300 shadow-2xl shadow-slate-500 hover:drop-shadow-2xl hover:shadow-white/[0.2] transition duration-300 cursor-pointer'>
                        <span className='w-full h-2/3 flex items-center justify-center'>
                            <MdPriceChange className='text-8xl' />
                        </span>
                        <span className='w-full h-1/3'>
                            <h2 className='text-center text-lg font-bold'>Pricing Module</h2>
                        </span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Products