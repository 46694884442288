import React from 'react'
// import intorVideo from '../../assets/merchmind_intro_updated.mp4'
import GetStarted from '../../assets/get_started.png'
import { Link } from 'react-router-dom'
import Button from '../../customcomponents/Button/Button'

const GetStartedSection = () => {
    return (
        <div className='w-full flex max-sm:flex max-sm:flex-col items-center justify-center gap-5'>
            {/* left */}
            <div className='w-1/2 max-sm:w-full p-10'>
                {/* <video src={intorVideo} autoPlay loop muted></video> */}
                <img src={GetStarted} alt="" />
            </div>

            {/* right */}
            <div className='w-1/2 max-sm:w-full max-sm:items-center max-sm:justify-center p-10 flex flex-col gap-10'>
                <h1 className='text-4xl font-bold leading-normal max-sm:text-center'>Get started with <br /> MerchMind today</h1>
                <p className='text-lg font-[600] text-gray-400 max-sm:text-center'>Request a personalized demo now or get in touch with one of our Connected Planning experts.</p>
                <div className='flex gap-2'>
                    {/* <Link to='/requestademo'>
                        <button className='p-2 bg-blue-600 text-white font-bold border-2 border-blue-600 hover:bg-white hover:text-black duration-300 mr-4'>Request a demo</button>
                    </Link> */}
                    {/* <Link
                        to="/requestademo"
                        className="mr-4 px-5 py-4 rounded-full relative bg-slate-600 text-white text-sm hover:bg-slate-500 transition duration-300 border border-slate-600">
                        <div className="absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl  bg-gradient-to-r from-transparent via-teal-400 to-transparent" />
                        <span className="relative z-20 font-[600]">
                            Request a Demo
                        </span>
                    </Link> */}
                    <Link to='/requestademo'>
                        <Button
                            btnText="Request a Demo"
                            btnTextColor="text-white"
                            btnBgColor="bg-slate-600"
                            btnBgColorHover="hover:bg-slate-500"
                            btnPadding="px-5 py-4"
                        />
                    </Link>
                    {/* <Link to='/requestademo'>
                        <button className='p-2 text-black font-bold border-2 border-blue-600 hover:bg-blue-600 hover:text-white duration-300 hover:border-blue-300'>Contact us</button>
                    </Link> */}
                    {/* <Link
                        to="/requestademo"
                        className="px-5 py-4 rounded-full relative text-sm border border-slate-600">
                        <div className="absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl  bg-gradient-to-r from-transparent via-teal-400 to-transparent" />
                        <span className="relative z-20 font-[600]">
                            Contact Us
                        </span>
                    </Link> */}
                    <Link to='/requestademo'>
                        <Button
                            btnText="Contact Us"
                            btnPadding="px-5 py-4"
                            btnBgColorHover="hover:bg-slate-200"
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default GetStartedSection