import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='w-full flex max-sm:flex max-sm:flex-col-reverse max-sm:gap-5 items-center justify-between p-10 bg-[#485478] text-white'>
            <div>
                <p className='font-bold'>&copy; 2024 MerchMind, Inc. All rights reserved</p>
            </div>

            <div className='max-sm:flex max-sm:items-center max-sm:justify-center max-sm:gap-5'>
                <Link 
                    to='/support'
                    className="block md:inline-block text-gray-400 hover:text-white duration-300 px-3 py-3 border-b-2 border-blue-900 md:border-none font-[600]"
                >
                    Support
                </Link>
                <Link 
                    to='/requestademo'
                    className="block md:inline-block text-gray-400 hover:text-white duration-300 px-3 py-3 border-b-2 border-blue-900 md:border-none font-[600]"
                >
                    Contact
                </Link>
                <Link 
                    to='/'
                    className="block md:inline-block text-gray-400 hover:text-white duration-300 px-3 py-3 border-b-2 border-blue-900 md:border-none font-[600]"
                >
                    English
                </Link>
            </div>
        </div>
    )
}

export default Footer