import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { links } from './MyLinks';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const NavLinks = ({ activeMenu }) => {
    const [heading, setHeading] = useState("");
    const [subHeading, setSubHeading] = useState("");
    return (
        <>
            {links.map((link) => (
                <div key={link.id}>
                    <div className="relative px-3 text-left md:cursor-pointer group bg-black text-white">
                        <Link to={link.mainLink}>
                            <h1
                                className="py-6 flex justify-between items-center md:pr-0 pr-5 group"
                                onClick={() => {
                                    heading !== link.name ? setHeading(link.name) : setHeading("");
                                }}
                            >
                                {link.name}
                                &nbsp;
                                <span className={`text-3xl md:hidden inline transition-transform duration-300 ${heading === link.name ? "rotate-180" : ""}`}>
                                    <MdOutlineKeyboardArrowDown className='text-teal-400' />
                                </span> 
                                <span className="text-xl md:block hidden duration-300 group-hover:rotate-180">
                                    <MdOutlineKeyboardArrowUp className='text-teal-400' />
                                </span>
                            </h1>
                        </Link>
                        {link.submenu && (
                            <div className="z-10">
                                <div className="w-[400px] h-full absolute top-16 right-4 hidden group-hover:md:block hover:md:block">
                                    <div className="py-3">
                                        <div className="w-4 h-4 right-1 absolute mt-1 bg-gray-800 rotate-45"></div>
                                    </div>
                                    <div className="bg-gray-800 text-gray-400 capitalize p-5 grid grid-cols-2 gap-4 rounded-tr-sm shadow-2xl shadow-gray-900">
                                        {link.sublinks.map((mysublinks) => (
                                            <div key={mysublinks.id}>
                                                <Link to={mysublinks.toLink}>
                                                    <h1 className="text-sm font-semibold hover:text-white p-2 duration-300">
                                                        {mysublinks.Head}
                                                    </h1>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Mobile menus */}
                    <div
                        className={`${heading === link.name ? "md:hidden" : "hidden"}`}
                    >
                        {/* sublinks */}
                        {link.sublinks.map((slinks) => (
                            <div key={slinks.id}>
                                <div>
                                    <Link to={slinks.toLink}>
                                        <h1
                                            onClick={() => {
                                                subHeading !== slinks.Head ? setSubHeading(slinks.Head) : setSubHeading("");
                                                activeMenu()
                                            }}
                                            className="py-3 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center"
                                        >
                                            {slinks.Head}
                                        </h1>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    )
}

export default NavLinks