import React from 'react'

const Customers = () => {
    return (
        <div className='w-full h-[800px] flex items-center justify-center'>
            <h1 className='text-2xl'>Customers...Coming soon</h1>
        </div>
    )
}

export default Customers