import React from 'react'

const Button = ({ btnText, btnTextColor, btnBgColor, btnBgColorHover, btnPadding }) => {
    return (
        <button
            className={`${btnPadding} rounded-full relative ${btnTextColor} hover:shadow-2xl ${btnBgColor} ${btnBgColorHover} hover:shadow-white/[0.2] transition duration-300 border border-slate-600`}>
            <div className="absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl  bg-gradient-to-r from-transparent via-teal-500 to-transparent" />
            <span className="relative font-[600] text-sm">
                {btnText}
            </span>
        </button>
    )
}

export default Button;