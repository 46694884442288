import React from 'react'
import { TbAutomaticGearbox } from 'react-icons/tb'

const AutoReplenishment = () => {
    return (
        <div className='w-full h-[800px] flex items-center justify-center'>
            <div className='w-[250px] h-[200px] flex flex-col items-center justify-center gap-4 border-2 p-2 rounded-lg border-blue-600 shadow-lg shadow-blue-900 cursor-pointer'>
                <span className='w-full h-2/3 flex items-center justify-center'>
                    <TbAutomaticGearbox className='text-8xl' />
                </span>
                <span className='w-full h-1/3'>
                    <h2 className='text-center text-lg font-bold'>Auto Replenishment</h2>
                </span>
            </div>
        </div>
    )
}

export default AutoReplenishment