import React from 'react'
import FeatureSection from '../FeatureSection/FeatureSection'

const InformationSection = () => {
    return (
        <div className='w-full h-[650px] max-sm:h-full md:h-full flex flex-col items-center justify-center gap-5 md:gap-10 p-10 max-sm:px-3 md:py-20'>
            <div className='w-[90%] max-sm:w-full md:w-full h-1/2 flex flex-col items-center justify-center gap-10'>
                <h2 className='text-3xl max-sm:text-2xl max-lg:text-3xl text-center px-[10rem] max-sm:p-5 max-lg:px-5 font-bold'>We have devoted many years to supporting business with data-driven assortment tools, dynamic pricing, and operation optimization.</h2>
                <p className='text-xl max-sm:text-xl text-center px-[10rem] max-sm:p-5 max-lg:px-5 font-[600] text-gray-400'>Today, businesses count on us to boost profitability, increase efficiency, & eliminate waste, achieve sustainable growth and profitability.</p>
            </div>

            <div>
                <FeatureSection />
            </div>
        </div>
    )
}

export default InformationSection